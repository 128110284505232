<template>
    <section class="search-box">
        <div class="options" @click="isShowSearchOption = !isShowSearchOption">
            <div class="search-by">
                <b-icon icon="search" class="icon"></b-icon>

                <label>ค้นหาด้วย</label>
                <span v-if="searchMode === 1">
                    ขนาดยาง <b-icon icon="chevron-down" class="arrow"></b-icon>
                </span>
                <span v-if="searchMode === 2">
                    จากรุ่นรถ <b-icon icon="chevron-down" class="arrow"></b-icon>
                </span>
                <span v-if="searchMode === 3">
                    การใช้งาน <b-icon icon="chevron-down" class="arrow"></b-icon>
                </span>
            </div>
            <div class="option-list" v-if="isShowSearchOption">
                <button class="btn-option" :class="{ 'active': searchMode === 1 }" @click="searchMode = 1">
                    ค้นหาจากขนาดยาง
                </button>
                <button class="btn-option" :class="{ 'active': searchMode === 2 }" @click="searchMode = 2">
                    ค้นหาจากรุ่นรถ
                </button>
                <button class="btn-option" :class="{ 'active': searchMode === 3 }" @click="searchMode = 3">
                    ค้นหาจากการใช้งาน
                </button>
            </div>
        </div>
        <div class="tire-search">
            <div class="form">
                <div class="search-mode" v-show="searchMode === 1">
                    <div class="field-option">
                        <div class="item">
                            <label>
                                ขนาดยาง
                            </label>
                            <dropdown
                                v-model="formSearchTire.width"
                                placeholder="ความกว้าง"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.width"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                    </div>
                    <div class="field-option">
                        <div class="item">
                            <label>
                                ซีรีย์
                            </label>
                            <dropdown
                                v-model="formSearchTire.ratio"
                                placeholder="ซีรีย์"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.ratio"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                    </div>
                    <div class="field-option">
                        <div class="item">
                            <label>
                                ขอบล้อ
                            </label>
                            <dropdown
                                v-model="formSearchTire.diameter"
                                placeholder="ขอบล้อ"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.diameter"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                    </div>
                </div>
                <div class="search-mode" v-show="searchMode === 2">
                    <div class="field-option width-50">
                        <div class="item">
                            <label>
                                ยี่ห้อรถ
                            </label>
                            <dropdown
                                v-model="formSearchCar.brand"
                                placeholder="ยี่ห้อรถ"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.brand"
                                @change="changeBrand($event)">
                            </dropdown>
                        </div>
                    </div>
                    <div class="field-option width-50">
                        <div class="item">
                            <label>
                                รุ่นรถ
                            </label>
                            <dropdown
                                v-model="formSearchCar.model"
                                placeholder="รุ่นรถ"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.model">
                            </dropdown>
                        </div>
                    </div>
                </div>
                <div class="search-mode" v-show="searchMode === 3">
                    <div class="field-option width-50">
                        <div class="item">
                            <label>
                                การใช้งาน
                            </label>
                            <dropdown
                                v-model="formSearchType.usage"
                                placeholder="การใช้งาน"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.usage">
                            </dropdown>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="operation">
                <button class="btn btn-search" @click="searchBySize()" v-if="searchMode === 1">
                    ค้นหายาง
                </button>
                <button class="btn btn-search" @click="searchByCar()" v-if="searchMode === 2">
                    ค้นหายาง
                </button>
                <button class="btn btn-search" @click="searchByType()" v-if="searchMode === 3">
                    ค้นหายาง
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import MixinTireSearch from '@/mixins/tireSearch';

export default {
    mixins: [ MixinTireSearch ],
    data() {
        return {
            isShowSearchOption: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search-box {
    display: flex;
    padding: 10px 0;
    margin-bottom: 10px;

    .options {
        position: relative;
        display: flex;
        background: #fff;
        height: 50px;
        cursor: pointer;

        .search-by {
            width: 220px;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background: #fffb40;

            @media only screen and (max-width: $screenLarge) {
                width: 150px;
            }

            .icon {
                position: absolute;
                color: #666;
                font-size: 18px;
                margin-left: 10px;
            }

            label {
                font-size: 11px;
                line-height: 11px;
                margin-bottom: 3px;
                color: #555;
                cursor: pointer;
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: bold;
                line-height: 13px;
                margin: 0;
                text-align: center;
                position: relative;

                .arrow {
                    position: absolute;
                    right: 10px;
                }
            }
        }

        .option-list {
            position: absolute;
            top: 100%;
            z-index: 1;
            background: #fff;
            @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

            .btn-option {
                width: 100%;
                display: block;
                font-size: 13px;
                padding: 10px 15px;
                color: #333;
                text-align: center;
                border: none;
                border-bottom: 1px solid #ddd;
                background: none;

                &:last-child {
                    border-bottom: none;
                }

                &.active {
                    font-weight: bold;
                }

                &:hover {
                    background: #4876b9;
                    color: #fff;
                    opacity: 1;
                }
            }
        }
    }

    .tire-search {
        display: flex;
        flex: 1;
        padding-left: 15px;     

        .form {
            flex: 1;

            .search-mode {
                display: flex;

                .field-option {
                    padding: 0 2px;
                    width: 33.333333%;
                    text-align: center;

                    &.width-50 {
                        width: 50%;
                    }

                    .item {
                        background: #fff;
                        height: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-top: 3px;
                        
                        label {
                            display: block;
                            color: #999;
                            font-size: 11px;
                            line-height: 11px;
                            padding-right: 20px;
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }

        .operation {
            width: 180px;
            padding-left: 10px;

            @media only screen and (max-width: $screenLarge) {
                width: 150px;
            }

            @media only screen and (max-width: $screenMedium) {
                width: 100px;
            }

            .btn-search {
                width: 100%;
                height: 50px;
                display: block;
                font-size: 13px;
                margin-left: 0;
                color: #fff;
                background: #b14646;
            }
        }        
    }
}

::v-deep .form-option {
    border: none;

    .input-option {
        font-size: 13px;
        border: none;
    }
}
</style>