import $ from 'jquery';

export default {
    created() {
        this.addEventListenerForArticle();
    },
    methods: {
        addEventListenerForArticle() {
            $('body').delegate('#articleContent img', 'click', function () {
                if ($(this).closest('a').length === 0) {
                    window.open($(this).attr('src'), '_blank');
                }
            });
        }
    }
}