<template>
    <section class="product-model" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
        <swiper :options="swiperOptions" class="sliders">
            <swiper-slide v-for="(item, index) in models" :key="index">
                <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, $route.params.brand + '?model=' + encodeURIComponent(item.title))" class="item" :title="item.title">
                    <img :src="item.picture" :alt="item.title" :title="item.title" @error="helper.setNoImg($event, 'product')" />
                    <span>
                        {{ item.title }}
                    </span>
                </router-link>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';

export default {
    mixins: [ MixinDragScroll ],
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper,
            swiperOptions: {
                loop: false,
                slidesPerView: 7,
                spaceBetween: 10,
                breakpoints: {
                    576: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 4
                    },
                    992: {
                        slidesPerView: 5
                    },
                    1200: {
                        slidesPerView: 6
                    }
                }
            }
        };
    },
    props: {
        models: Array
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-model {
    width: 1001px;
    overflow: hidden;

    @media only screen and (max-width: $screenExtraLarge) {
        width: 100%;
    }

    .sliders {
        overflow: hidden;

        .item {
            cursor: pointer;
            display: block;
            background: none;

            img {
                border: 1px solid #333;
            }

            span {
                margin-top: 3px;
                font-size: 12px;
                line-height: 14px;
                color: #000;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 13px;
                    line-height: 15px;
                }
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }
}
</style>