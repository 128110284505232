
<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-compare-table modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <b-icon icon="pencil-square"></b-icon>
                            ตารางเปรียบเทียบ
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>        
                    <div class="modal-body">
                        <section class="compare-grid">
                            <div class="product"
                                v-for="(item, index) in compareList"
                                :key="index">
                                <compare-box
                                    :product="item">
                                </compare-box>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>     
    </transition>
</template>

<script>
import CompareBox from '@/components/product/CompareBox';

export default {
    components: {
        CompareBox
    },
    data() {
        return {
            isActive: false
		};
    },
    props: {
        compareList: Array,
        isDisplay: Boolean,
    },
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
    methods: {
        hide() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-compare-table {
    max-width: 1200px;

    .compare-grid {
        @include gridContentWrapper(0 -5px);
        justify-content: flex-start;

        .product {
            display: block;
            padding: 0 5px 5px;
            width: 25%;

            @media only screen and (max-width: $screenSmall) {
                width: 33.33333%;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                width: 50%;
            }
        }
    }
}
</style>