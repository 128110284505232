<template>
    <section class="search-box">
        <div class="options" @click="isShowSearchForm = true">
            <div class="search-by">
                <b-icon icon="search" class="icon"></b-icon>

                <label v-if="searchMode === 1">
                    ค้นหาด้วยขนาดยาง
                </label>
                <label v-if="searchMode === 2">
                    ค้นหาด้วยรุ่นรถ
                </label>
                <label v-if="searchMode === 3">
                    ค้นหาด้วยการใช้งาน
                </label>

                <span>{{ searchTerm }}</span>
            </div>
        </div>

        <div class="tire-search" v-if="isShowSearchForm">
            <div class="form">
                <div class="panel-title">
                    <h4>แก้ไขการค้นหา</h4>
                    <button class="btn-close" @click="isShowSearchForm = false"><b-icon icon="x"></b-icon></button>
                </div>

                <div class="picture">
                    <img src="@/assets/decor-tire-size-320x108.webp" title="ค้นหาจากขนาดยาง" alt="ค้นหาจากขนาดยาง" v-if="searchMode === 1" />
                    <img src="@/assets/decor-tire-car-320x108.webp" title="ค้นหาจากยี่ห้อรถ" alt="ค้นหาจากยี่ห้อรถ" v-if="searchMode === 2" />
                    <img src="@/assets/decor-tire-type-320x108.webp" title="ค้นหาจากประเภท" alt="ค้นหาจากประเภท" v-if="searchMode === 3" />
                </div>

                <div class="panel-fields">
                    <div class="option-list">
                        <button class="btn-option" :class="{ 'active': searchMode === 1 }" @click="searchMode = 1">
                            ขนาดยาง
                        </button>
                        <button class="btn-option" :class="{ 'active': searchMode === 2 }" @click="searchMode = 2">
                            จากรุ่นรถ
                        </button>
                        <button class="btn-option" :class="{ 'active': searchMode === 3 }" @click="searchMode = 3">
                            การใช้งาน
                        </button>
                    </div>
                    
                    <div class="search-mode" v-show="searchMode === 1">
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchTire.width"
                                placeholder="ความกว้าง"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.width"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchTire.ratio"
                                placeholder="ซีรีย์"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.ratio"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchTire.diameter"
                                placeholder="ขอบล้อ"
                                :classes="{ input: [ 'text-center' , 'input-option' ] }"
                                :options="option.diameter"
                                @change="changeTireSizeOption()">
                            </dropdown>
                        </div>
                    </div>
                    <div class="search-mode" v-show="searchMode === 2">
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchCar.brand"
                                placeholder="ยี่ห้อรถ"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.brand"
                                @change="changeBrand($event)">
                            </dropdown>
                        </div>
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchCar.model"
                                placeholder="รุ่นรถ"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.model">
                            </dropdown>
                        </div>
                    </div>
                    <div class="search-mode" v-show="searchMode === 3">
                        <div class="field-option">
                            <dropdown
                                v-model="formSearchType.usage"
                                placeholder="การใช้งาน"
                                :classes="{ input: [ 'text-center', 'input-option' ] }"
                                :options="option.usage">
                            </dropdown>
                        </div>  
                    </div>
                    <div class="operation">
                        <button class="btn btn-main btn-search" @click="search()">
                            ค้นหายาง
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MixinTireSearch from '@/mixins/tireSearch';

export default {
    mixins: [ MixinTireSearch ],
    data() {
        return {
            isShowSearchForm: false,
            searchTerm: '-'
        }
    },
    mounted() {
        this.searchTerm = this.displaySearchTerm();
    },
    methods: {
        displaySearchTerm() {
            let text = '-';
            const usage = this.option.usage.find(x => x.id === this.formSearchType.usage);

            switch (this.searchMode) {
                case 1:
                    if (!this.formSearchTire.width && !this.formSearchTire.ratio && !this.formSearchTire.diameter) {
                        text = '-';
                    } else {
                        text = `${this.formSearchTire.width || '_'}/${this.formSearchTire.ratio || '_'}R${this.formSearchTire.diameter || '_'}`;
                    }
                    
                    break;

                case 2:
                    text = `${this.formSearchCar.brand} ${this.formSearchCar.model}`;
                    break;

                case 3:
                    text = usage ? usage.name : '-';
                    break;
            }

            return text;
        },
        search() {
            switch (this.searchMode) {
                case 1:
                    this.searchBySize();
                    break;

                case 2:
                    this.searchByCar();
                    break;

                case 3:
                    this.searchByType();
                    break;
            }

            this.isShowSearchForm = false;
            this.searchTerm = this.displaySearchTerm();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search-box {
    display: flex;
    padding: 10px 0;
    margin-bottom: 10px;

    .options {
        position: relative;
        display: flex;
        background: #fff;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        cursor: pointer;

        .search-by {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .icon {
                position: absolute;
                color: #333;
                font-size: 18px;
                right: 10px;
            }

            label {
                font-size: 11px;
                line-height: 11px;
                margin-bottom: 3px;
                color: #999;
                cursor: pointer;
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: bold;
                line-height: 13px;
                margin: 0;
            }
        }
    }
}

.tire-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.8);

    .panel-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        background: #444;

        h4 {
            margin: 0;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
        }

        .btn-close {
            font-size: 22px;
            border: none;
            background: none;
            line-height: 22px;
            margin: 0;
            padding: 0;
            color: #fff;
        }
    }

    .form {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        padding: 0 0 20px 0;
        z-index: 2;
        background: #fff;

        .picture {
            img {
                display: block;
                width: 100%;
            }
        }

        .panel-fields {
            padding: 20px;

            .option-list {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ddd;
                margin-bottom: 15px;

                button {
                    color: #666;
                    background: none;
                    border: none;
                    margin-right: 5px;
                    font-size: 13px;

                    &.active {
                        color: #000;
                        font-weight: bold;
                        border-bottom: 2px solid #ffc107;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .search-mode {
                display: block;

                .field-option {
                    padding-top: 10px;
                }
            }

            .operation {
                margin-top: 15px;
                
                .btn-search {
                    width: 100%;
                    height: 45px;
                    display: block;
                    font-size: 15px;
                    font-weight: bold;
                    @include borderRadius(5px);
                }
            }
        }
    }
}

::v-deep .form-option {
    border: 1px solid #ccc;

    .input-option {
        font-size: 15px;
        height: 45px;
        border: none;
    }
}
</style>