const option = [
    { id: 'popular', name: 'ยางยอดนิยม' },
    { id: 'priceAsc', name: 'ราคาน้อยไปมาก' },
    { id: 'priceDesc', name: 'ราคามากไปน้อย' },
    { id: 'model', name: 'ชื่อรุ่น' },
    { id: 'speedDesc', name: 'อัตราความเร็วมากไปน้อย' },
    { id: 'weightDesc', name: 'การรับน้ำหนักมากไปน้อย' }
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};