<template>
    <div class="compare-list">
        <div class="compare-box">
            <div class="container">
                <h3>
                    รายการเปรียบเทียบ
                    <button class="btn btn-secondary" @click="showTable()">เปิดตาราง</button>
                </h3>
                <div class="compare-list" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()">
                    <div class="item" v-for="(item, index) in 4" :key="index">
                        <template v-if="index < compareList.length">
                            <div class="wrapper">
                                <b-icon icon="x" class="btn-remove" @click="removeCompare(index)"></b-icon>
                                <div class="picture">
                                    <img :src="compareList[index].picture" :alt="compareList[index].name" :title="compareList[index].name" @error="helper.setNoImg($event, 'product')" />
                                </div>
                                <div class="desc">
                                    <img :src="compareList[index].brandLogo" :alt="compareList[index].brand" :title="compareList[index].brand" class="brand" @error="helper.setNoImg($event, 'brand')" />
                                    <h4>ยาง <strong>{{ compareList[index].brand }}</strong></h4>
                                    <h4>รุ่น <strong>{{ compareList[index].name }}</strong></h4>
                                    <h4>ขนาด <strong>{{ compareList[index].size }}</strong></h4>

                                    <div class="price-data" v-if="compareList[index].priceRange">
                                        <div class="price">
                                            <span>{{ compareList[index].priceRange }}</span>
                                        </div>
                                    </div>

                                    <div class="price-data" v-else>
                                        <div class="price">
                                            <span class="grey">ไม่มีจำหน่าย</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="wrapper empty">
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <modal-compare-table :isDisplay="isShowTable" :compareList="compareList" @close="closeTable()" />
    </div>
</template>

<script>
import ModalCompareTable from '@/components/product/ModalCompareTable';

export default {
    components: {
        ModalCompareTable
    },
    data() {
        return {
            isShowTable: false
        }
    },
    props: {
        compareList: Array
    },
    methods: {
        removeCompare(index) {
            this.$emit('removeCompare', index);
        },
        showTable() {
            this.isShowTable = true;
        },
        closeTable() {
            this.isShowTable = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.compare-list {
    .compare-box {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 0;
        background: rgba(0, 0, 0, 0.75);

        h3 {
            position: relative;
            font-size: 16px;
            font-family: 'Open Sans', 'Kanit';
            color: #fff;
            padding-bottom: 5px;
            font-weight: bold;

            .btn {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 12px;
                padding: 1px 10px;
            }
        }

        .compare-list {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            .item {
                width: 25%;
                min-width: 277px;
                padding-right: 5px;

                .wrapper {
                    position: relative;
                    display: flex;
                    height: 100%;
                    padding: 10px;
                    background: #fff;

                    &.empty {
                        background: none;
                        border: 1px dashed #ccc;
                    }
                }

                .btn-remove {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    font-size: 24px;
                    color: #cc0000;
                }

                .picture {
                    width: 120px;
                    border: 1px dashed #ccc;

                    img {
                        width: 100%;
                    }
                }

                .desc {
                    flex: 1;
                    padding: 0 10px;

                    .brand {
                        display: block;
                        width: 100px;
                        border: 1px solid #ccc;
                        margin-bottom: 10px;
                    }

                    h4 {
                        font-size: 13px;
                        margin: 0;
                    }

                    .price-data {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        padding-top: 10px;

                        @media only screen and (max-width: $screenExtraSmall) {
                            padding-top: 5px;
                        }

                        .price {
                            line-height: normal;

                            span {
                                font-size: 13px;
                                color: #cc0000;
                                white-space: nowrap;

                                &.grey {
                                    color: #999;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>