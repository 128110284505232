<template>
	<div class="products">
		<div class="top-box">
			<div class="container">
				<div class="d-none d-md-block">
					<tire-search-box
						:tireSizeOption="tireSizeOptionData" 
						:carBrandData="carBrandData"
						:tireTypeData="tireTypeData"
						v-if="isContentReady && tireSizeOptionData && carBrandData.length > 0 && tireTypeData.length > 0">
					</tire-search-box>
				</div>
				<div class="d-block d-md-none">
					<tire-search-box-mobile
						:tireSizeOption="tireSizeOptionData" 
						:carBrandData="carBrandData"
						:tireTypeData="tireTypeData"
						v-if="isContentReady && tireSizeOptionData && carBrandData.length > 0 && tireTypeData.length > 0">
					</tire-search-box-mobile>
				</div>
			</div>
		</div>

		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageProductList" :isBannerReady="isContentReady"></gadget-ads-banner>

			<div class="content-container mt-3">
				<div class="filter">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<template v-if="isContentReady">
						<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
							<div class="box" @click.stop>
								<perfect-scrollbar>
									<div class="filter-title">
										<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
									</div>
									<filter-search
										v-if="filterOptions && tags"
										ref="filterSearch"
										:filterOptions="filterOptions"
										:tagSelections="tags"
										filterBy="product"
										@filterResult="changeFilter($event)">
									</filter-search>
								</perfect-scrollbar>
								<div class="operation-panel">
									<div class="wrapper">
										<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
										<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()">ดูสินค้า {{ totalResult }} รายการ</button>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="content-body">
					<div class="content-mobile-filter mb-3">
						<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
					</div>

					<div class="list">
						<product-result-title class="result-title" :title="resultTitle" :desc="resultDesc" :totalResult="totalResult">ผลการค้นหา</product-result-title>

						<template v-if="isResultReady">
							<product-model class="mt-3 mb-3" :models="modelByBrands" v-if="modelByBrands.length > 0 && !$route.params.model"></product-model>
						</template>
							
						<gadget-sort-bar :sortOption="sortOption" :defaultOption="sortOption[0].id" @changeSort="changeSort($event)" class="mt-3 mb-3"></gadget-sort-bar>

						<div class="loading" v-if="!isResultReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>

						<template v-if="isResultReady">
							<gadget-search-tag 
								:tags="tags"
								@removeTag="removeTag($event)"
								class="mb-3"
								v-if="tags && tags.length > 0">
							</gadget-search-tag>
							<product-grid
								:products="resultList"
								:isShowCompare="true"
								@openStoreList="openStoreList($event)"
								@setCompare="setCompare($event)"
								grid="column-5"
								trackingReferrer="product">
							</product-grid>
							<paging
								@page="changePage($event)"
								:activePage="activePage"
								:totalPage="totalPage"
								class="mt-4 mb-2">
							</paging>
						</template>
					</div>

					<article id="articleContent" class="content-article mt-4 mb-4" v-html="content" v-if="content"></article>

					<blog-relate v-if="isContentReady"
						class="mt-4"
						:relatePromotions="relatePromotions"
						:relateArticlesLatest="relateArticlesLatest"
						:relateArticlesByCategory="relateArticlesByCategory">
					</blog-relate>
				</div>
			</div>

			<product-compare
				v-if="compareList.length > 0"
				:compareList="compareList"
				@removeCompare="removeCompare($event)">
			</product-compare>
		</div>
	</div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import OptionProductSort from '@/services/staticOption/productSort';
import MixinArticleContent from '@/mixins/articleContent';
import ProductService from '@/services/productService';
import MasterService from '@/services/masterService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import TireSearchBox from '@/components/product/TireSearch';
import TireSearchBoxMobile from '@/components/product/TireSearchMobile';
import FilterSearch from '@/components/gadget/FilterSearch';
import ProductGrid from '@/components/product/ProductGridList';
import ProductModel from '@/components/product/ProductModel';
import ProductResultTitle from '@/components/product/ResultTitle';
import GadgetSortBar from '@/components/gadget/SortBar';
import GadgetSearchTag from '@/components/gadget/SearchTag';
import ProductCompare from '@/components/product/ProductCompare';
import BlogRelate from '@/components/blog/BlogRelate';

export default {
	mixins: [ MixinArticleContent ],
	components: {
		GadgetAdsBanner,
		TireSearchBox,
		TireSearchBoxMobile,
		FilterSearch,
		ProductGrid,
		ProductResultTitle,
		ProductModel,
		GadgetSortBar,
		GadgetSearchTag,
		ProductCompare,
		BlogRelate
	},
	data() {
		return {
			routerPath: RouterPath,
			isContentReady: false,
			isResultReady: false,
			isShowMobileFilter: false,
			isFirstLoad: true,
			topBanner: null,
			tags: null,
			resultTitle: '',
			resultDesc: '',
			pageTitle: 'ราคายางรถยนต์ทุกยี่ห้อ ทุกรุ่น เช็คราคายาง โปรโมชั่นยาง | YELLOWTiRE',
			metaDescription: 'เช็คราคายางรถยนต์ทุกยี่ห้อ ทุกรุ่น ทุกขนาด ค้นหายางพร้อมข้อมูลยาง และโปรโมชั่นราคายางรถยนต์อัพเดตล่าสุด |YELLOWTiRE.com ที่สุดเรื่องยาง เช็ค ชอป ชัวร์',
			modelByBrands: [],
			filterOptions: [],
			resultList: [],
			totalResult: 0,
			activePage: 1,
			totalPage: 0,
			filter: null,
			sortOption: OptionProductSort.option,
			sortType: 'popular',
			tireSizeOptionData: null,
			carBrandData: [],
			tireTypeData: [],
			compareList: [],
			author: '',
			publisher: '',
			keyword: 'ราคายางรถยนต์,ยางรถยนต์,ยาง,ยางรถ,ราคายาง,เช็คราคายางรถยนต์,จัดส่ง,ติดตั้ง,การเปลี่ยนยาง,โปรโมชั่นยางรถยนต์,ร้านยางใกล้ฉัน,ยางรถยนต์ขอบ15,ยางรถยนต์ขอบ16,ยางรถยนต์ขอบ17,ยางรถยนต์ขอบ18,bridgestone,dunlop,michelin,yokohama,maxxis,goodyear,nitto,continental,toyo,deestone,apollo,firestone,nitto,hankook,ผ่อนยาง 0%',
			content: '',
			socialImage: location.origin + Helper.metaDefault().logo,
			relatePromotions: [],
			relateArticlesLatest: [],
			relateArticlesByCategory: []
		};
	},
	mounted() {
        this.initTireOption();
        this.initCarOption();
		this.initTireTypeOption();
        this.getContent();
    },
    methods: {
		async initTireOption() {
            const result = await MasterService.getAllTireSizeOption();

            this.tireSizeOptionData = result.data;
        },
        async initCarOption() {
            const result = await MasterService.getCarBrand();

            this.carBrandData = result.data;
        },
		async initTireTypeOption() {
            const result = await MasterService.getTireType();

            this.tireTypeData = result.data;
        },
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamContent();
            const result = await ProductService.getContentSearch(param);

			if (result?.data) {
				this.pageTitle = result.data.pageTitle;
				this.metaDescription = result.data.metaDescription;
				this.topBanner = result.data.ads;
				this.filterOptions = result.data.filter;
				this.modelByBrands = result.data.modelByBrands;
				this.author = result.data.author;
				this.publisher = result.data.publisher;
				this.keyword = result.data.keyword;
				this.content = result.data.content;
				this.socialImage = result.data.socialImage;
				this.relatePromotions = result.data.promotions;
				this.relateArticlesLatest = result.data.articlesLatest;
				this.relateArticlesByCategory = result.data.articlesByCategory;
				this.isContentReady = true;

				this.getSearchResult();
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
        },
		async getSearchResult() {
            this.isResultReady = false;

			const param = this.setupParamSearch();
            const result = await ProductService.searchProduct(param);

			if (result) {
				this.resultTitle = result.data.resultTitle || 'ยางรถยนต์ทั้งหมด';
				this.resultDesc = result.data.resultDesc;
				this.resultList = result.data.resultList;
				this.tags = result.data.tags;
				this.totalPage = result.data.totalPage;
				this.totalResult = result.data.totalResult;
				this.isResultReady = true;
			}
        },
		readSavedFilter() {
            const filterStorage = localStorage.filter ? localStorage.filter : '';
            const isIncludeSavedFilter = Helper.getQueryString('filter');
			
			if (this.isFirstLoad && filterStorage && isIncludeSavedFilter === 'true') {
				this.isFirstLoad = false;

				return JSON.parse(decodeURIComponent(atob(filterStorage)));
			}
            
            return null;
        },
		setupParamContent() {
			const pageName = Helper.getProductSearchPageName();
			const param = {
				referer: pageName,
				keyword: Helper.getQueryString('k'),
				brand: this.$route.params.brand,
				model: Helper.getQueryString('model'),
				width: Helper.getQueryString('tw'),
				ratio: Helper.getQueryString('tr'),
				diameter: Helper.getQueryString('td'),
				carBrand: Helper.getQueryString('cb'),
				carModel: Helper.getQueryString('cm'),
				carYear: Helper.getQueryString('cy'),
				carType: Helper.getQueryString('ct'),
				feature: Helper.getQueryString('ft'),
				special: Helper.getQueryString('sp'),
				promotion: Helper.getQueryString('pr'),
				orderType: Helper.getQueryString('ot'),
			};

			return param;
		},
		setupParamSearch() {
			const savedFilter = this.readSavedFilter();
			const generalParam = {
				page: this.activePage,
				savedFilter: savedFilter ? savedFilter.filter : null,
				filter: this.filter,
				sort: this.sortType
			};
			const urlParam = this.setupParamContent();
			const param = { ...generalParam, ...urlParam };

			return param;
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changePage(page) {
			this.activePage = page;
			this.getSearchResult();
		},
		changeSort(data) {
			this.sortType = data.value;

			if (this.sortType) {
				this.getSearchResult();
			}
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
			this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		},
		removeTag(data) {
			const tagIndex = this.tags.findIndex(x => x.value === data.item.value);
			this.tags.splice(tagIndex, 1);
			this.$refs.filterSearch.updateFilterSelection(this.tags);
		},
        setCompare(item) {
			if (this.compareList.length < 4) {
				const compare = this.compareList.find(x => x.id === item.id);

				if (!compare) {
					this.compareList.push(item);
				}
			}
        },
        removeCompare(index) {
            this.compareList.splice(index, 1);
        }
    },
	metaInfo() {
        return {
            title: this.pageTitle,
            meta: [
				{ vmid: 'author', name: 'author', content: this.author },
				{ vmid: 'publisher', name: 'publisher', content: this.publisher },
				{ vmid: 'keyword', name: 'keywords', content: this.keyword },
                { vmid: 'desc', name: 'description', content: this.metaDescription },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.pageTitle },
                { vmid: 'og-desc', property: 'og:description', content: this.metaDescription },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: location.origin + Helper.metaDefault().logo },
				{ vmid: 'twitter-description', property: 'twitter:description', content: this.metaDescription },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: location.origin + Helper.metaDefault().logo }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.products {
	padding: 0 0 15px;

	.top-box {
		background-color: #25221e;
		background: url(/img/decoration-tire.jpg);
        background-size: cover;
		@include boxShadow(0 0 5px rgba(100, 100, 100, 0.6));
	}

	.list {
		.loading {
			@include gridContentWrapper(0 -5px);

			.item {
				display: block;
				width: 100%;
				height: 250px;
			}
		}
	}

	article {
		padding: 20px;
		background: #fff;
	}
}
</style>