<template>
    <div class="box">
        <router-link 
            :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', product.param)" class="title" :title="product.size + ' ' + product.brand + ' ' + product.name">
            <img :src="product.brandLogo" :alt="product.brand" :title="product.brand" class="brand" @error="helper.setNoImg($event, 'brand')" />
            <h4>ขนาด <strong>{{ product.size }}</strong></h4>
            <h4><span>ยางรถยนต์</span> <strong>{{ product.brand }}</strong></h4>
            <h4><strong>{{ product.name }}</strong></h4>
        </router-link>

        <router-link 
            :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', product.param)" class="picture" :title="product.size + ' ' + product.brand + ' ' + product.name">
            <img :src="product.picture" :alt="product.name" :title="product.name" @error="helper.setNoImg($event, 'product')" />
        </router-link>

        <div class="price-data">
            <label>ราคาเริ่มต้น</label>
            <div class="price">
                <span :class="{ 'grey': !product.priceRange }">{{ product.priceRange ? product.priceRange : 'ไม่มีจำหน่าย' }}</span>
                <div class="promotion" v-if="product.displayPromotion">
                    {{ product.displayPromotion }}
                </div>
            </div>
        </div>

        <div class="text-center mt-2">
            <router-link
                :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', product.param)" class="btn btn-grey btn-search" :title="product.size + ' ' + product.brand + ' ' + product.name">
                ดูรายละเอียด
            </router-link>
        </div>

        <div class="info mt-3">
            <div class="data">
                <label>หน้ายางกว้าง</label>
                <span>{{ product.tireWidth1 }}</span>
                <span>{{ product.tireWidth2 }}</span>
            </div>
            <div class="data">
                <label>ความสูง ของแก้มยาง (ซีรีย์)</label>
                <span>{{ product.tireRatio }}</span>
                <span>{{ product.tireRatioUnit }}</span>
            </div>
            <div class="data">
                <label>เส้นผ่านศูนย์กลาง กระทะล้อ</label>
                <span>{{ product.tireDiameter }}</span>
                <span>{{ product.tireDiameterUnit }}</span>
            </div>
            <div class="data">
                <label>แก้มยางสูง</label>
                <span>{{ product.sidewall1 }}</span>
                <span>{{ product.sidewall2 }}</span>
            </div>
            <div class="data">
                <label>ความสูงของยางโดยรวม</label>
                <span>{{ product.tireHeight1 }}</span>
                <span>{{ product.tireHeight2 }}</span>
            </div>
            <div class="data">
                <label>ความเร็วสูงสุด</label>
                <span>รหัส {{ product.maxSpeedUnit }}</span>
                <span>{{ product.maxSpeedKm }}</span>
            </div>
            <div class="data">
                <label>รับน้ำหนักต่อล้อ</label>
                <span>รหัส {{ product.weightTireIndex }}</span>
                <span>{{ product.weightTireKg }}</span>
            </div>
        </div>

        <div class="icons">
            <div class="country"><img :src="product.country.flag" :alt="product.country.text" :title="product.country.text" /></div>
            <div class="item"><img v-if="product.tsiPicture" :src="product.tsiPicture" :alt="'มอก. ' + product.name" :title="'มอก. ' + product.name" /></div>
            <div class="item"><img v-if="product.performance.icon" :src="product.performance.icon" :alt="'Performance ' + product.name" :title="'Performance ' + product.name" /></div>
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';

export default {
    mixins: [ MixinDragScroll ],
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper
        };
    },
    props: {
        product: {
            type: [ Array, Object ],
            required: true
        }
    },
    methods: {
        getTitleName() {
            return [ this.product.brand, this.product.name, this.product.size ].join(' ');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/product/style/CompareBox.scss';
</style>